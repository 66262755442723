import React from "react";

import SecretLayout from "../components/secret-layout/secret-layout";
import builderSlothImage from "../images/BuilderSloth.png";
import * as styles from "./secret-under-construction.module.scss";

const SecretUnderConstruction = () => {
    return (
        <SecretLayout title="Under Construction">
            <div className={styles.container}>
                
                <img
                        className={styles.image}
                        src={builderSlothImage}
                        width="200"
                        height="200"
                        alt="builder-sloth"
                    />
            </div>
        </SecretLayout>  
    );
}

export default SecretUnderConstruction;